export const paymentConstants = {
  // payment 종류
  PAYMENT_TYPE_GENERAL: "PAYMENT_TYPE_GENERAL",
  PAYMENT_TYPE_TEST: "PAYMENT_TYPE_TEST",

  // 결제 정보
  PAYMENT_INFO_REQUEST: "PAYMENT_INFO_REQUEST",
  PAYMENT_INFO_SUCCESS: "PAYMENT_INFO_SUCCESS",
  PAYMENT_INFO_FAILURE: "PAYMENT_INFO_FAILURE",

  // 결제 정보 v2
  PAYMENT_COURSE_REQUEST: "PAYMENT_COURSE_REQUEST",
  PAYMENT_COURSE_SUCCESS: "PAYMENT_COURSE_SUCCESS",
  PAYMENT_COURSE_FAILURE: "PAYMENT_COURSE_FAILURE",
};

export const BANK_CODE = {
  신한: "366",
  농협: "371",
};

export const NEED_DELIVERY_COURSE_KEYWORDS = [
  "sparta_free_pass",
  "certificate-computer-iPad",
  "certificate_eip1",
  " certificate_eip1_s2",
  "certificate_ebda1",
  "certificate_eip2_s2",
];
