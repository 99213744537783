import {
  doneFeedback,
  pendingFeedback,
  review,
} from "../../pages/HomeworkPage/FeedbackNotice/StatusExplanation.model";

export const MAX_FILE_SIZE_MB = 300;

export const StatusEnum = {
  SUBMITTED: "SUBMITTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  RESOLVED: "RESOLVED",
};

export const validStatusValues = [
  StatusEnum.SUBMITTED,
  StatusEnum.ACCEPTED,
  StatusEnum.RESOLVED,
];

export const canGoNextLectureStatusValues = [
  StatusEnum.ACCEPTED,
  StatusEnum.RESOLVED,
];

export const KDCHomeworkFeedbackStatusModel = (status) => {
  switch (status) {
    case StatusEnum.SUBMITTED:
      return review;
    case StatusEnum.ACCEPTED:
      return pendingFeedback;
    case StatusEnum.RESOLVED:
      return doneFeedback;
    default:
      return { key: "", title: "", content: "" };
  }
};

export const totalQnALink = "https://spartacodingclub.kr/community/fastqna/all";

export const UPDATE_SUCCESS_ALERT =
  "숙제를 제출했습니다.\n\n올바른 형식으로 숙제를 제출하셨는지 24시간 이내 검토 후, 카카오톡으로 안내드릴 예정입니다. 검토 확인 전까지 다음 강의를 수강하실 수 없습니다.";

export const NEED_SCREENING_ALERT =
  "숙제 파일을 검토 중 입니다. 24시간 이내 검토 후, 카카오톡으로 안내드릴 예정입니다.";
