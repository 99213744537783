import * as S from "./HomeworkGnbDesktop.style";
import { DesktopNextBtn } from "../../../../../pages/LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { BackIconSvg } from "../../../../../pages/LecturePageV3/Icons";
import { useNavigateToRoadmap } from "../../../../../hooks/useNavigateToRoadmap";
import { useNextContentCtaText } from "../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";

export const HomeworkGnbDesktop = ({
  week,
  isInit,
  isOpenList,
  courseTitle,
  syllabusUnit,
  canGoNextLecture,
  canUpdateHomework,
  isFeedbackRequired,
  handleSubmitHomework,
}) => {
  const navigateToRoadmap = useNavigateToRoadmap();
  const ctaText = useNextContentCtaText(
    isFeedbackRequired,
    canGoNextLecture,
    "숙제 제출하기"
  );
  return (
    <S.HomeworkGnbWrapper isOpenList={isOpenList}>
      <S.HomeworkGnbLeftSide>
        <S.GoPrevPageBtn
          onClick={() => {
            navigateToRoadmap();
          }}
        >
          <BackIconSvg />
        </S.GoPrevPageBtn>
        <S.LectureTitleAndWeekWrapper>
          <S.LectureTitle>{courseTitle}</S.LectureTitle>
          <S.LectureTitleAndWeekDivider />
          <S.LectureWeek>
            {week}
            {syllabusUnit}
          </S.LectureWeek>
        </S.LectureTitleAndWeekWrapper>
      </S.HomeworkGnbLeftSide>
      <S.HomeworkGnbRightSide>
        <DesktopNextBtn
          text={ctaText}
          onClick={handleSubmitHomework}
          isActive={isInit}
          isClickable={canGoNextLecture ? true : canUpdateHomework}
        />
      </S.HomeworkGnbRightSide>
    </S.HomeworkGnbWrapper>
  );
};
