export const review = {
  key: "파일 검토",
  title: "숙제 파일을 검토하고 있습니다.",
  content:
    "올바른 형식으로 숙제를 제출하셨는지 24시간 이내 검토 후, 카카오톡으로 안내드릴 예정입니다.\n" +
    "검토 확인 전까지 다음 강의를 수강하실 수 없습니다.",
};

export const pendingFeedback = {
  key: "피드백 대기",
  title: "담당 튜터가 1:1 피드백을 작성하고 있습니다.",
  content:
    "영업일 기준 2~3일 내 피드백 완료 후, 카카오톡으로 개별 안내드릴 예정입니다.",
};

export const doneFeedback = {
  key: "피드백 완료",
  title: "담당 튜터의 1:1 피드백이 등록되었습니다.",
  content: "튜터님께서 작성해주신 맞춤 피드백을 확인해 보세요!",
};
